import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import api from '../../services/api'
import { DataTableOptions } from '../../components/dataTableOptions'
import {useNavigate} from "react-router";
import ITest from "../../interfaces/ITest";

export default function ListingTests() {
  const navigate = useNavigate()
  const [tests, setTests] = useState<ITest[]>([])

  const user = JSON.parse(localStorage.getItem('token') || "").user

  const getTests = async () => {
    await api.get<ITest[]>('/api/tests').then((response) => {
      const data = response.data
      setTests(data)
    })
  }

  useEffect(() => {
    if(user?.organizer === "0" && user?.role != "admin"){
      localStorage.setItem('hasError', JSON.stringify({title:"Atenção!" ,message:"Você não tem permissão. ",type: 'danger'}));
      return navigate("/home");
    }

    getTests()
  }, [])

  const columns: any = [
    {
      name: 'title', label: 'Titulo', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'description', label: 'Descrição', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'active', label: 'Ativa', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: '', label: '', options: {
        filter: false,
        false: true,
        customBodyRenderLite: index => {return (
            <>
              <Link className='btn btn-sm btn-warning' to={`/adm/tests/update/${tests[index]?.id}`} title="Editar"><i className="fa fa-pencil" aria-hidden="true"></i> </Link>
            </>
          )
        },
      },
    },
  ]

  const options: any = DataTableOptions

  return (
    <>
      <h2>Provas</h2>

      <div className='mb-4'>
        <Link className='btn btn-success mb-3' to='/adm/tests/create'>Cadastrar Prova</Link>

        <MUIDataTable
          title={''}
          data={tests}
          columns={columns}
          options={options}
        />
      </div>

    </>
  )
}

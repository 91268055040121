import axios from "axios";
import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {formatDa} from "../../assets/js/helper";
import SearchPeople from "../../components/searchPeople";
import IClass from "../../interfaces/IClass";
import api from "../../services/api";
import Swal from "sweetalert2";
import SpreadsheetPrinting from '../spreadsheet/SpreadsheetPrinting'


export default function Class2() {
    const {id} = useParams()

    const [classes, setClasses] = useState<IClass[] | any>([])
    const [categories, setCategories] = useState<any>([])
    const [teams, setTeams] = useState<any>([])

    const userLogado = JSON.parse(localStorage.getItem('token') || "").user.id
    const tipoUser = JSON.parse(localStorage.getItem('token') || "").user
    const athleteLogado = JSON.parse(localStorage.getItem('token') || "").user.athlete

    const navigate = useNavigate();

    const [data, setData] = useState('');

    const childToParent = (childdata) => {
        // console.log(childdata)
        controlaPosto(null, childdata.station, childdata.atlhelete, true)
        // setData(childdata);
    }

    const buscaClass = () => {
        // axios.get<ITest[]>('http:///api/classes')
        api.get('/api/classes/' + id)
            .then(resposta => {
                setClasses([resposta.data.data])
                setCategories(resposta.data.categories)
                setTeams(resposta.data.teams)
            }).catch(erro => {
                // console.log(erro.response)
                Swal.fire({
                    title: 'Erro!',
                    text: erro.response.data.sqlMessage,
                    icon: 'error',
                    timer: 5000
                }).then((result) => {
                    return navigate(-1)
                });
            }
        );
    }

    useEffect(() => {
        buscaClass()
    }, [])

    const controlaPosto = (event: React.MouseEvent<HTMLButtonElement> | null, posto_id: number, athlete_id: number, addModal = false) => {

        if(athlete_id == null || addModal){
            reqControlaPosto(posto_id,athlete_id,addModal)
        }else{
            Swal.fire({
                icon: 'info',
                title: `Tem certeza que deseja ${tipoUser.role == 'athlete' ? "sair do posto" : "remover o atleta"}? `,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
            }).then((result) => {
                if (result.isConfirmed) {
                    reqControlaPosto(posto_id,athlete_id,addModal)
                } else  {
                    return false
                }
            })
        }

    }

    const closeClasse = (event: React.MouseEvent<HTMLButtonElement> | null,classe_id: number, close: string = 'closed') => {
        console.log(close)
        const closeClass = (close == 'closed')
        Swal.fire({
            title: 'Tem certeza que deseja ' + ( closeClass ? 'fechar' : 'finalizar') + ' a turma? ',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                api.post('/api/classes/close/' + classe_id,{close: (closeClass ? true : false)})
                  .then(resposta => {
                      Swal.fire({
                          title: 'Sucesso!',
                          text: "Turma " + ( closeClass ? 'fechada' : 'finalizada') + " com sucesso",
                          icon: 'success',
                          timer: 5000
                      }).then((result) => {
                          return navigate(-1)
                      });
                      // setClasses([resposta.data.data])
                  }).catch(erro => {
                      // console.log(erro.response)
                      Swal.fire({
                          title: 'Erro!',
                          text: erro.response.data.sqlMessage,
                          icon: 'error',
                          timer: 5000
                      }).then((result) => {
                          // return navigate(-1)
                      });
                  }
                );
            } else  {
                return false
            }
        })

    }

    const reqControlaPosto = (posto_id: number, athlete_id: number, addModal = false) => {

        //
        // if(atletasEquipe >= 3){
        //     Swal.fire({
        //         title: 'Atenção!',
        //         text: 'Numero máximo de atletas da mesma equipe atingido.',
        //         icon: 'warning',
        //     })
        //     return false
        // }

        api.post('/api/classes/' + id + '/athlete/' + (athlete_id == null ? athleteLogado?.id : athlete_id) + '/station/' + posto_id, {acao: athlete_id == null || addModal ? 'add' : 'remove'})
            .then(resposta => {
                // console.log(resposta)
                if (resposta.status == 200) {
                    //$(`#modalSearchPeople${posto_id}`).modal('hide');

                    // if(resposta.data.infoMessage && resposta.data.infoMessage.length > 0){
                    //     for(const message of resposta.data.infoMessage){
                    //         Swal.fire({
                    //             title: 'Atenção!',
                    //             text: 'resposta.data.infoMessage',
                    //             icon: 'warning',
                    //         })
                    //     }
                    // }

                    if (tipoUser.role != 'athlete') {
                        buscaClass();
                    }
                        Swal.fire({
                            title: 'Sucesso!',
                            text: resposta.data.message,
                            icon: 'success',
                            timer: 2000
                        }).then((result) => {
                            if (tipoUser.role != 'athlete') {
                                buscaClass();
                            // (document.getElementById(`modalSearchPeople${posto_id}`)as any).style.display = "none";
                            // ($('#deletePhotoConfirmation') as any).modal('show');
                            return navigate(0);
                            }else{
                                return navigate(`/classes/${classes[0].date_id}`)
                            }
                        });

                } else {
                    // console.log(resposta.data)
                    Swal.fire({
                        title: 'Erro!',
                        text: JSON.stringify(resposta),
                        icon: 'error',
                    })
                }
            }).catch(erro => {
                console.log(erro)
                Swal.fire({
                    title: 'Erro!',
                    text: erro.message,
                    icon: 'error',
                    // text: erro.response?.data?.error?.sqlMessage,
                })
            }
        );


    };

    //     except('update') metodo q não quero usar


    let estouEmAlgum = classes[0] != undefined && classes[0].stations?.filter(element => element.athlete?.user_id == userLogado).length > 0;

    let atletasEquipe = classes[0] != undefined && classes[0].stations?.filter(element => element.athlete?.team_id == athleteLogado?.team_id).length;

    classes.forEach(cla => {
        cla.stations?.forEach(station => {
            station.active = (athleteLogado?.id != station.athlete?.id ? (station.athlete == null ? estouEmAlgum : true) : !(station.athlete != null && (athleteLogado?.id == station.athlete?.id)))
        })
    });

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h3 className="mb-2">
                        <b>Agendamento</b>
                    </h3>
                </div>
            </div>
            <div className="row my-4">
                {classes.map(classe =>

                    <div key={classe.id} className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                              <h4 className='my-0 font-weight-normal'>{classe.title} &nbsp;
                                {tipoUser.role != 'athlete' && tipoUser?.organizer === "1" ?
                                  <>
                                  <SpreadsheetPrinting classID={classe.id} />
                                      <div className="btn-group" style=
                                        {{
                                            float: 'right',
                                        }}>
                                          <button type="button" className="btn btn-sm btn-warning"  onClick={e => {
                                              closeClasse(e, classe.id, 'finished')
                                          }}> <i className="fa fa-window-close-o" aria-hidden="true"></i> &nbsp; Finalizar Turma
                                          </button>
                                          <button type="button"
                                                  className="btn btn-sm btn-warning dropdown-toggle dropdown-toggle-split"
                                                  data-toggle="dropdown" aria-expanded="false">
                                              <span className="sr-only">Dropdown</span>
                                          </button>
                                          <div className="dropdown-menu" >
                                              <a className="dropdown-item"  onClick={e => {
                                                  closeClasse(null, classe.id)
                                              }}href="#"><i className="fa fa-window-close-o" aria-hidden="true"></i> &nbsp; Fechar Turma</a>
                                          </div>
                                      </div>
                                  </>

                                  : null
                                }
                              </h4>


                            </div>
                            <div className="card-body">
                                <h1><b>{formatDa('2022-1-1 ' + classe.time, 'hour')}</b><small
                                    className="text-muted">&nbsp;{parseInt(classe.time) > 11 ? 'PM' : 'AM'}</small></h1>
                                <div className="table-responsive">
                                    <table className="table table-striped table-borderless table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Posto</th>
                                            <th scope="col">Nome</th>
                                            {tipoUser.role != 'athlete' ?
                                                    <>
                                                    <th>Categoria</th>
                                                    <th>Equipe</th>
                                                    </>
                                                    :null
                                                }
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {classe.stations?.map(posto =>
                                            <tr key={posto.station}
                                                className={tipoUser.role == 'athlete' && athleteLogado?.id == posto.athlete?.id ? 'table-danger' : ''}>
                                                <th scope="row">{posto.station}</th>
                                                <td>{posto.athlete?.full_name || 'Livre'}</td>
                                                {tipoUser.role !== 'athlete' &&
                                                    <>
                                                        <td>{categories.find(category => category.id == posto.athlete?.category_id)?.description || ''}</td>
                                                        <td>{teams.find(team => team.id == posto.athlete?.team_id)?.name || ''}</td>
                                                    </>
                                                }

                                                <td>
                                                    {tipoUser.role == 'athlete' ? (
                                                        <button type="button" disabled={posto.active} onClick={e => {
                                                            controlaPosto(e, posto.station, posto.athlete?.id)
                                                        }}
                                                                className={`btn mx-2 btn-sm ${posto.active ? 'btn-outline-secondary' : athleteLogado?.id == posto.athlete?.id ? 'btn-danger' : 'btn-success'}`}>
                                                            {athleteLogado?.id == posto.athlete?.id ? 'Sair' : 'Entrar'}
                                                        </button>
                                                    ) : (
                                                        <>
                                                            {posto.athlete == null ?
                                                                <>
                                                                    <p>{data}</p>
                                                                    <SearchPeople childToParent={childToParent}
                                                                                  station={posto.station}/>
                                                                </>
                                                                :
                                                                <button type="button"
                                                                        className={'btn mx-2 btn-sm btn-danger'}
                                                                        onClick={e => {
                                                                            controlaPosto(e, posto.station, posto.athlete?.id)
                                                                        }}> Remover </button>
                                                            }
                                                        </>
                                                    )
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </div>
            <div className="row py-4">
                <div className="col-md-12  text-right">
                    <button className="btn btn-danger btn-floating" onClick={() => navigate(`/classes/${classes[0].date_id}`)}>Voltar<br/></button>
                </div>
            </div>
        </>
    );
}

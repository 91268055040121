import React, { useState } from 'react'
import api from '../../services/api'
import html2pdf from 'html2pdf.js'
import {formatDa} from "../../assets/js/helper";
export default function ProofRegistrationPrinting({test_id}) {
  const [spreadsheet, setSpreadsheet] = useState<any>({})
  const [loading, setLoading] = useState<any>(true)

  const handleGeneratePDF = () => {
    const element = document.getElementById('sheet0')
    var opt = {
      filename: 'comprovante.pdf',
      html2canvas: {
        // dpi: 192,
        scale: 2,
      },
      jsPDF: {format: 'a5', orientation: 'landscape' },
    }

    html2pdf().set(opt).from(element).toPdf().get('pdf').then(function(pdfObj) {
      // pdfObj has your jsPDF object in it, use it as you please!
      // For instance (untested):
      pdfObj.autoPrint();
      window.open(pdfObj.output('bloburl'), '_blank')
    })
  }

  const printPDF = () => {
    api.post(`/api/tests/proofRegistration/${test_id}`)
      .then(resposta => {
        setSpreadsheet(resposta.data)
        setLoading(false)
      }).then(res =>{
      setTimeout(function() {handleGeneratePDF()}, 300);
    })
  }

  return (
      <>
        <button className='btn btn-info' onClick={printPDF}><i className="fa fa-print"
                                                                      aria-hidden="true"></i> &nbsp; Imprimir
          comprovante
        </button>

        {!loading &&
            <div style={{display: 'none'}}>
              <div id="sheet0" className="sheet0">
                <div className='m-4'>
                  <div className="row col-sm-12">
                    <div className="col-sm-2 m-auto">
                      <div>
                        <img style={{zIndex: '1', right: '0px', top: '12px', height: '60px'}}
                             src='/images/interclubes.svg'/>
                      </div>
                    </div>
                    <div className="col-sm-8 text-center">
                      <span>Interclubes</span> <br/>
                      <span><b>Comprovante de inscrição online</b></span> <br/>
                      <span>{spreadsheet?.body?.competition?.title}</span> <br/>
                      <span>{spreadsheet?.body?.description}</span> <br/>
                      <span>{spreadsheet?.body?.team?.club?.address?.street}, {spreadsheet?.body?.team?.club?.address?.number} - {spreadsheet?.body?.team?.club?.address?.city?.name}/{spreadsheet?.body?.team?.club?.address?.city?.state?.abbreviation}</span>
                    </div>
                    <div className="col-sm-2 m-auto">
                      &nbsp;
                    </div>

                  </div>
                  <div className="row col-sm-12 mt-3 mb-3">
                    <div className="col-sm-12">
                      <p><b>Atleta:</b> <span>{spreadsheet?.athlete?.full_name}</span></p>
                      <p><b>Equipe:</b> <span>{spreadsheet?.athlete?.team?.name}</span></p>
                      <p><b>Categoria:</b> <span>{spreadsheet?.athlete?.category?.description}</span></p>
                      <p><b>Genero: </b> <span>{spreadsheet?.athlete?.genre === 'M' ? "Masculino": 'Feminino'}</span></p>
                    </div>

                  </div>
                  <div className="col-sm-12 text-center mb-2" >
                    <span><b>Agendamentros</b></span>
                  </div>
                  <div className=" row col-sm-12">

                    <table className={'table table-sm table-bordered'} >
                      <tr>
                        <th>Nr</th>
                        <th>Data</th>
                        <th>Turma</th>
                        <th>Posto</th>
                      </tr>
                      {spreadsheet.tests.map((res, index) =>
                          <tr key={index} >
                            <td>{index + 1}</td>
                            <td>{formatDa(res?.dat || '', 'data')}</td>
                            <td>{res?.clas}</td>
                            <td>Posto {res?.pos}</td>
                          </tr>
                      )}
                    </table>
                  </div>

                  {/*<div className="mt-3 row col-sm-12">*/}
                  {/*<p>Registro de inscrição em: </p>*/}
                  {/*</div>*/}

                </div>
              </div>
            </div>
        }
      </>
  )
}




import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// import Nav from './components/navbar'
// import Footer from './components/footer'
import Test from './pages/test/test'
import Class from './pages/class/class'
import NotFound from './pages/pages/NotFound'
import Class2 from './pages/class/class2'
import Content from './components/content'
import Register from './pages/register/register'
import Login from './pages/login/login'
import ProtectedRoutes from './components/ProtectedRoutes'
import Dates from './pages/date/date'
import Spreadsheet from "./pages/spreadsheet/spreadsheet";
import Result from "./pages/result/result";
import Profile from "./pages/profile/profile";
import Competition from "./pages/competition/Competition";
import Home from "./pages/home";
import Forgot from './pages/forgot/forgot'
import Reset from './pages/reset/reset'
import ListingAthletes from "./pages/athlete/listing";
import FormAthletes from './pages/athlete/form'
import GeneralResult from './pages/result/GeneralResult'
import FrameShooters from './pages/frame/FrameShooters'
import ChangePassword from "./pages/user/ChangePassword/change";
import FormTests from "./pages/test/form";
import ListingTests from "./pages/test/listing";
import ListingCompetitios from "./pages/competition/listing";
import FormCompetitions from "./pages/competition/form";
import ListingTeams from "./pages/team/listing";
import FormTeam from "./pages/team/form";
import {Outlet} from "react-router";
import {useContext} from "react";
import ListingClubs from "./pages/club/listing";
import FormClub from "./pages/club/form";
import FormCalendar from "./pages/Calendar/form";
import ListingCalendars from "./pages/Calendar/listing";

const PrivateRoutes = ({role= ''}) => {
    // const { authenticated, authUser } = useContext(AuthContext)
    //
    // if(!authenticated) return <Navigate to='/login' replace />
    //
    // if(authenticated && !!role && role !== authUser.role) return <Navigate to='/home' replace />
    //
    // return <Outlet />
}


export default function AppRouter() {

    return (
    <div className="App">

            <Router>
                <Routes>

                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/forgotPassoword' element={<Forgot />} />
                    <Route path='/resetPassword/:token' element={<Reset />} />

                    <Route path='' element={ <Content />}>
                        <Route path='*' element={<NotFound />} />
                        <Route path='/' element={<ProtectedRoutes />}>
                            {/*<Route index element={<Content />} />*/}
                            <Route path='home' element={<Home />} />
                            <Route path='competitions' element={<Competition />} />
                            <Route path='tests/:id' element={<Test />} />
                            <Route path='profile' element={<Profile />} />

                            <Route path='changePassword' element={<ChangePassword />} />
                            <Route path='frameShooters/:id' element={<FrameShooters />} />

                            <Route path='spreadsheet/:id' element={<Spreadsheet />} />
                            <Route path='spreadsheet/update/:id' element={<Spreadsheet />} />
                            <Route path='dates/:id' element={<Dates />} />
                            <Route path='classes/:id' element={<Class />} />
                            <Route path='class/:id' element={<Class2 />} />
                            <Route path='test/:id/results' element={<Result />} />
                            <Route path='competition/:id/results' element={<GeneralResult />} />

                        </Route>

                        <Route path='/adm' element={<ProtectedRoutes />}>
                            <Route path='teams' element={<ListingTeams />} />
                            <Route path='teams/create' element={<FormTeam />} />
                            <Route path='teams/update/:id' element={<FormTeam />} />

                            <Route path='clubs' element={<ListingClubs />} />
                            <Route path='clubs/create' element={<FormClub />} />
                            <Route path='clubs/update/:id' element={<FormClub />} />

                            <Route path='athletes' element={<ListingAthletes />} />
                            <Route path='athletes/create' element={<FormAthletes />} />
                            <Route path='athletes/update/:id' element={<FormAthletes />} />

                            <Route path='competitions' element={<ListingCompetitios />} />
                            <Route path='competitions/create' element={<FormCompetitions />} />
                            <Route path='competitions/update/:id' element={<FormCompetitions />} />

                            <Route path='competitions/calendar/:id' element={<ListingCalendars />} />
                            <Route path='competitions/calendar/create/:competitionId' element={<FormCalendar />} />
                            <Route path='competitions/calendar/update/:id' element={<FormCalendar />} />

                            <Route path='tests' element={<ListingTests />} />
                            <Route path='tests/create' element={<FormTests />} />
                            <Route path='tests/update/:id' element={<FormTests />} />

                        </Route>
                    </Route>

                </Routes>
            </Router>
        </div>
    )
}

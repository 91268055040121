import React, {useContext, useEffect} from "react";
import ChangePasswordForm from "./changePasswordForm";
import {PasswordProvider} from "../../../context/PasswordContext";

export default function ChangePassword() {

    return (
        <>
            <PasswordProvider>
                <ChangePasswordForm/>
            </PasswordProvider>
        </>
    );
}

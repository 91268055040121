import api from '../../services/api'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {useParams} from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { SelectTheme } from '../../components/selectTheme'

export default function FormCompetitions() {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('token') || "").user

    let {id} = useParams()
    const [active, setActive] = useState(true)
    const [status, setStatus] = useState('soon')
    const [showResult, setShowResult] = useState(1)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const dispatchCompetitionsObject: any = {
        "id": id,
        "active": active,
        "status": status,
        "title": title,
        "show_result": showResult,
        "description": description,
        "image": ""
    }

    useEffect(() => {
        if (id != undefined) {
            api.get('/api/competitions/' + id)
                .then(resposta => {
                    const test = resposta.data.data

                    setActive(test?.active)
                    setStatus(test?.status)
                    setShowResult(test?.show_result)
                    setTitle(test?.title)
                    setDescription(test?.description)
                })

        }
    }, [])

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            const method:'post' | 'put' = id === undefined ? 'post' : 'put';
            const url:string = id === undefined ? '/api/competitions' : `/api/competitions/${id}`;

            api[method](url, dispatchCompetitionsObject)
                .then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        timer: 3000
                    }).then(() => navigate("/adm/competitions"));
                })
                .catch(erro => {
                    Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.sqlMessage,
                        icon: 'error',
                    });
                }).finally(()=>{
                    // parar o loading
                });
        }catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className='text-left'>

                <div className='form-row'>

                    <div className='form-group col-md-4'>
                        <label htmlFor='active'>Ativo <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Ativo', value: true}}
                            options={[{label: 'Ativo', value: true}, {label: 'Inativo', value: false}]}
                            value={[{label: 'Ativo', value: true}, {
                                label: 'Inativo',
                                value: false,
                            }].find(option => option.value == active)}
                            name='active'
                            id='active'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setActive(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='status'>Status <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Em breve', value: 'soon'}}
                            options={[{label: 'Em breve', value: 'soon'}, {
                                label: 'Em andamento',
                                value: 'in_progress'
                            }, {label: 'Finalizada', value: 'finished'}]}
                            value={[{label: 'Em breve', value: 'soon'}, {
                                label: 'Em andamento',
                                value: 'in_progress'
                            }, {label: 'Finalizada', value: 'finished'}
                            ].find(option => option.value == status)}
                            name='status'
                            id='status'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setStatus(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>
                    <div className='form-group col-md-4'>
                        <label htmlFor='show_result'>Mostrar resultados <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Sim', value: 1}}
                            options={[{label: 'Sim', value: 1}, {label: 'Não', value: 0}]}
                            value={[{label: 'Sim', value: 1}, {label: 'Não', value: 0}
                            ].find(option => option.value == showResult)}
                            name='show_result'
                            id='show_result'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setShowResult(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />
                    </div>

                    <div className='form-group col-md-12'>
                        <label htmlFor='title'>Titulo <i className="text-danger"> *</i></label>
                        <input onChange={evento => setTitle(evento.target.value)} type='text' className='form-control'
                               value={title}
                               id='title' placeholder='Titulo'/>
                    </div>

                    <div className='form-group col-md-12'>
                        <label htmlFor='description'>Descrição </label>
                        <input onChange={evento => setDescription(evento.target.value)} type='text'
                               className='form-control'
                               value={description}
                               id='description' placeholder='Descrição'/>
                    </div>
                </div>

                <div className='row py-4'>
                    <div className='col-md-12  text-right'>
                        <button type='button' className='btn btn-danger mr-1' onClick={() => navigate(-1)}>Cancelar<br/>
                        </button>
                        <button type='submit' className='btn btn-success'> Salvar</button>
                    </div>
                </div>
            </form>
        </>

    )
}

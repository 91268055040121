import {useEffect, useState} from 'react'
import api from '../../services/api'
import {useNavigate} from 'react-router'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import {useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import Async from "react-select/async";
import axios from "axios";
import { SelectTheme } from '../../components/selectTheme'

export default function FormClub() {
    const navigate = useNavigate()

    let {id} = useParams()

    const [active, setActive] = useState('1')
    const [name, setName] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [fantasia, setFantasia] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [phone, setPhone] = useState('' as any)
    const [addressId, setAddress] = useState(0)

    const [mapsLocation, setMapsLocation] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [street, setStreet] = useState('')
    const [number, setNumber] = useState('')
    const [district, setDistrict] = useState('')
    const [complement, setComplement] = useState('')
    const [city, setCity] = useState({} as any)

    const objReg: any = {
        "id": id,
        "active": active,
        "name": name,
        "razao_social": razaoSocial,
        "fantasia": fantasia,
        "cnpj": cnpj,
        "maps_location": mapsLocation,
        "phone": phone,
        "image": '',
        "address": {
            "id": addressId,
            "street": street,
            "number": number,
            "district": district,
            "zip_code": zipCode,
            "complement": complement,
            "city_id": city.value,
        }
    }

    useEffect(() => {

        if (id != undefined) {
            api.get('/api/clubs/' + id)
                .then(resposta => {
                    const club = resposta.data

                    setActive(club?.active)
                    setName(club?.name)
                    setRazaoSocial(club?.razao_social)
                    setFantasia(club?.fantasia)
                    setCnpj(club?.cnpj)
                    setPhone(club?.phone)

                    setAddress(club.address?.id)
                    setMapsLocation(club?.maps_location)

                    setZipCode(club?.address.zip_code)
                    setStreet(club?.address.street)
                    setNumber(club?.address.number)
                    setDistrict(club?.address.district)
                    setComplement(club?.address.complement)
                    setCity({
                        value: club?.address.city_id,
                        label: club?.address.city.name + ' - ' + club?.address.city.state.abbreviation,
                    })
                })
        }

    }, [])

    async function cidades(value = '') {
        const response = await api.get(`/api/cities/?search=${value}`)
        const result = await response.data
        return await result.data.map((city) => ({
            label: city.name + ' - ' + city.state.abbreviation,
            value: city.id,
        }))
    }

    async function buscaEndereco(cep = '') {
        cep = cep.replace('-', '')

        if (cep != '') {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            if (response.status == 200 && !response.data.erro!) {
                setStreet(response.data.logradouro);
                setNumber('');
                setDistrict(response.data.bairro);
                setComplement(response.data.complemento);

                const res = await api.get(`/api/searchCityIbge/?cod=${response.data.ibge}`)
                setCity({value: res.data.data.id, label: res.data.data.name})
            }
        }
    }

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            if (id == undefined) {
                // api.post('/api/athletes', objReg).then((result) => {
                //   console.log(result)
                //   Swal.fire({
                //     title: 'Sucesso!',
                //     text: result.data.message,
                //     icon: 'success',
                //     timer: 5000
                //   }).then((result) => {
                //     // if(!formReduzido){
                //     //   return navigate("/login");
                //     // }else{
                //     //   resetaForm();
                //     // }
                //   });
                // })
                //   .catch(erro => {
                //       console.log(erro.response)
                //       Swal.fire({
                //         title: 'Erro!',
                //         text: erro.response.data.sqlMessage,
                //         icon: 'error',
                //       })
                //     }
                //   );


            } else {
                api.put('/api/clubs/' + id, objReg).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                    }).then((result) => {
                        // return navigate("/adm/athletes");
                    });
                })
                    .catch(erro => Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.sqlMessage,
                        icon: 'error',
                    }))
            }
        } catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className='text-left'>

                <div className='form-row'>
                    <div className='form-group col-md-4'>
                        <label htmlFor='active'>Ativo <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Ativo', value: '1'}}
                            options={[{label: 'Ativo', value: '1'}, {label: 'Inativo', value: '0'}]}
                            value={[{label: 'Ativo', value: '1'}, {
                                label: 'Inativo',
                                value: '0',
                            }].find(option => option.value == active)}
                            name='active'
                            id='active'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setActive(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />
                    </div>
                </div>

                <div className='form-row'>
                    {/*<div className='form-group col-md-12'>*/}
                    {/*    <label htmlFor='name'>Nome da equipe <i className="text-danger"> *</i></label>*/}
                    {/*    <input onChange={evento => setName(evento.target.value)} type='text' className='form-control'*/}
                    {/*           value={name}*/}
                    {/*           id='name' placeholder='Nome da equipe' />*/}
                    {/*</div>*/}

                    <div className='form-group col-md-12'>
                        <label htmlFor='razao_social'>Razão Social <i className="text-danger"> *</i></label>
                        <input onChange={evento => setRazaoSocial(evento.target.value)} type='text' className='form-control'
                               value={razaoSocial}
                               id='razao_social' placeholder='Razão Social' />
                    </div>

                    <div className='form-group col-md-12'>
                        <label htmlFor='fantasia'>Fantasia <i className="text-danger"> *</i></label>
                        <input onChange={evento => setFantasia(evento.target.value)} type='text' className='form-control'
                               value={fantasia}
                               id='fantasia' placeholder='Razão Social' />
                    </div>

                    <div className="form-group col-md-6">
                        <label htmlFor="cnpj">CNPJ</label>
                        <InputMask mask="99.999.999/9999-99" onChange={evento => setCnpj(evento.target.value)} type="text"
                                   className="form-control"
                                   value={cnpj}
                                   id="cnpj" placeholder="Seu CNPJ"/>
                    </div>

                    <div className='form-group col-md-6'>
                        <label htmlFor='phone'>Celular <i className="text-danger"> *</i></label>
                        <InputMask mask='(99) 99999-9999' onChange={evento => setPhone(evento.target.value)} type='text'
                                   className='form-control'
                                   value={phone} id='phone' placeholder='Celular do capitão' />
                    </div>

                </div>


                <h3 className="mt-3">Endereço</h3>

                <div className='form-row'>
                    <div className='form-group col-md-4'>
                        <label htmlFor='zipCode'>Cep <i className="text-danger"> *</i></label>
                        <InputMask mask='99999-999'
                                   onChange={evento => setZipCode(evento.target.value)} type='text'
                                   onBlur={evento => buscaEndereco(evento.target.value)}
                                   className='form-control'
                                   value={zipCode}
                                   id='zipCode' placeholder='Seu cep' />

                    </div>
                    <div className='form-group col-md-8'>
                        <label htmlFor='street'>Rua <i className="text-danger"> *</i></label>
                        <input onChange={evento => setStreet(evento.target.value)} type='text'
                               className='form-control'
                               value={street}
                               id='street' placeholder='Rua' />
                    </div>
                    <div className='form-group col-md-4'>
                        <label htmlFor='number'>Numero <i className="text-danger"> *</i></label>
                        <input onChange={evento => setNumber(evento.target.value)} type='text'
                               className='form-control'
                               value={number}
                               id='number' placeholder='Numero' />
                    </div>

                    <div className='form-group col-md-8'>
                        <label htmlFor='district'>Bairro <i className="text-danger"> *</i></label>
                        <input onChange={evento => setDistrict(evento.target.value)} type='text'
                               value={district}
                               className='form-control' id='district' placeholder='Seu bairro' />
                    </div>
                    <div className='form-group col-md-6'>
                        <label htmlFor='complement'>Complemento <i className="text-danger"> *</i></label>
                        <input onChange={evento => setComplement(evento.target.value)} type='text'
                               value={complement}
                               className='form-control' id='complement' placeholder='Ex: Ap 123' />
                    </div>

                    <div className='form-group col-md-6'>
                        <label htmlFor='city'>Cidade <i className="text-danger"> *</i></label>

                        <Async
                            name='search'
                            isLoading={false}
                            cacheOptions
                            loadOptions={cidades}
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            placeholder='Sua cidade'
                            onChange={(data: any) => {
                                setCity(data)
                            }}
                            value={city}
                            theme={(theme) => (SelectTheme(theme))}
                            defaultOptions
                        />

                    </div>

                    <div className='form-group col-md-12'>
                        <label htmlFor='observation'>Localização Maps <i className="text-danger"> *</i></label>
                        <textarea id="observation" name="observation" className='form-control'
                                  value={mapsLocation} onChange={evento => setMapsLocation(evento.target.value)}
                                  rows={4} cols={50}/>
                    </div>

                </div>

                <div className='row py-4'>
                    <div className='col-md-12  text-right'>
                        <button type='button' className='btn btn-danger mr-1' onClick={() => navigate(-1)}>Cancelar<br/> </button>
                        <button type='submit' className='btn btn-success'> Salvar</button>
                    </div>
                </div>
            </form>

        </>

    )
}
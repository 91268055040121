import {createContext, ReactNode, useState} from 'react'

type Props = {
    children?: ReactNode;
}

let initialValue = {
    password: '',
    setPassword: () => {},
    passwordConfirmation: '',
    setPasswordConfirmation: () => {},
    currentPassword: '',
    setCurrentPassword: () => {},
}
const PasswordContext = createContext<any>(initialValue)

const PasswordProvider = ({children}: Props) => {
    const [password, setPassword] = useState(initialValue.password);
    const [passwordConfirmation, setPasswordConfirmation] = useState(initialValue.passwordConfirmation);
    const [currentPassword, setCurrentPassword] = useState(initialValue.currentPassword);

    const validatePasswordRegister = (change  = false) => {
        if (change){
            if (!currentPassword) {
                throw "Sua senha atual deve ser informada";
            }
        }

        if (!password) {
            throw "Nova senha deve ser informada";
        }

        if (!passwordConfirmation) {
            throw "Confimação da senha deve ser informada";
        }
    }

    const passwordIdent = () => {
        console.log(password == passwordConfirmation)
         if (password != passwordConfirmation){
             throw "Senhas devem ser iguais!";
         }
    }


    return (
     <>
        <PasswordContext.Provider value={{
            password, setPassword,
            passwordConfirmation, setPasswordConfirmation,
            currentPassword, setCurrentPassword,
            validatePasswordRegister,
            passwordIdent
        }}>
            {children}
        </PasswordContext.Provider>
     </>
    )
}

export { PasswordContext, PasswordProvider }

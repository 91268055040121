import React, {useContext, useEffect, useRef, useState} from "react";
import Swal from "sweetalert2";
import {PasswordContext} from "../../context/PasswordContext";

export default function PasswordForm({changePassword = false , customLabel={}}:any) {
    const {setPassword, setPasswordConfirmation, setCurrentPassword, password , passwordConfirmation} = useContext(PasswordContext)

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [capsLockOn, setCapsLockOn] = useState(false);
    const inputRefs = useRef<HTMLInputElement[]>([]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const isCapsLockOn = e.getModifierState('CapsLock');
            setCapsLockOn(isCapsLockOn);
        };

        inputRefs.current.forEach((inputRef) => {
            inputRef.addEventListener('keydown', handleKeyDown);
            inputRef.addEventListener('focusout', handleFocusOut);
        });

        return () => {
            inputRefs.current.forEach((inputRef) => {
                inputRef.removeEventListener('keydown', handleKeyDown);
                inputRef.removeEventListener('focusout', handleFocusOut);
            });
        };
    }, []);

    const handleFocusOut = () => {
        setCapsLockOn(false);
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    return (
        <>
            {changePassword &&
                <div className='form-row'>
                    <div className="form-group col-md-12">
                            <label htmlFor="currentPassword"
                                   className="form-label">{!customLabel?.currentPassword ? "Senha atual" : customLabel?.currentPassword}
                                <i className="text-danger"> *</i></label>
                            <div className="input-group ">
                                <input ref={(ref) => ref && inputRefs.current.push(ref)}
                                       type={isPasswordVisible ? 'text' : 'password'}
                                       className="form-control" id="currentPassword" placeholder="******"
                                       onChange={evento => setCurrentPassword(evento.target.value)}/>
                                <span className="input-group-text" id="inputGroupPrepend"
                                      onClick={togglePasswordVisibility}>
                                <i className={`fa fa-fw field-icon toggle-password ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i> </span>
                            </div>
                    </div>
                </div>
            }

            <div className='form-row'>
                <div className="form-group col-md-6">
                    <label htmlFor="password"
                           className="form-label">{!customLabel?.password ? "Senha" : customLabel?.password} <i
                        className="text-danger"> *</i> </label>
                    <div className="input-group ">
                        <input ref={(ref) => ref && inputRefs.current.push(ref)}
                               type={isPasswordVisible ? 'text' : 'password'} className="form-control" id="password"
                               placeholder="******"
                               onChange={evento => setPassword(evento.target.value)}/>
                        <span className="input-group-text" id="inputGroupPrepend" onClick={togglePasswordVisibility}>
                        <i className={`fa fa-fw field-icon toggle-password ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i> </span>
                    </div>
                </div>

                <div className="form-group col-md-6">
                    <label htmlFor="passwordConfirmation"
                           className="form-label">{!customLabel?.passwordConfirmation ? "Confirme a senha" : customLabel?.passwordConfirmation}
                        <i className="text-danger"> *</i></label>
                    <div className="input-group ">
                        <input ref={(ref) => ref && inputRefs.current.push(ref)}
                               type={isPasswordVisible ? 'text' : 'password'} className="form-control"
                               id="passwordConfirmation" placeholder="******"
                               onBlur={event => {
                                   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                   (password != passwordConfirmation) ? (
                                       Swal.fire({
                                           title: 'Erro!',
                                           text: 'Senhas devem ser iguais!',
                                           icon: 'error',
                                       })
                                   ) : "";
                               }}
                               onChange={evento => setPasswordConfirmation(evento.target.value)}/>
                        <span className="input-group-text" id="inputGroupPrepend" onClick={togglePasswordVisibility}>
                        <i className={`fa fa-fw field-icon toggle-password ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i> </span>
                    </div>
                </div>
            </div>


            {capsLockOn &&
                <div className="col g-3">
                    <span className="text-danger"><b>Caps lock está ligado</b></span>
                </div>
            }

        </>
    );
}

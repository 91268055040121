import {useEffect, useState} from "react";
import api from "../../services/api";
import {Link} from "react-router-dom";
import ITest from "../../interfaces/ITest";
import CompetitionCalendar from "../Calendar/CompetitionCalendar";

export default function Competition() {
    const [competitions, setCompetitions] = useState<ITest[]>([])

    useEffect(() => {
        // axios.get<ITest[]>('http:///api/competitions')
        api.get('/api/competitions')
            .then(resposta => setCompetitions(resposta.data.data))
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h3 className="mb-2">
                        <b>Competições</b>
                    </h3>
                </div>
            </div>
            <div className="row my-4">

                {competitions.map(competition =>
                    <div key={competition.id} className="col-md-4 mt-3">
                        {/* <div className="card"> <img className="card-img-top" src="https://static.pingendo.com/cover-moon.svg" alt="Card image cap" /> */}
                        <div className="card"> <img className={competition.status === 'finished' ? 'rounded mx-auto d-block img-blur' : 'rounded mx-auto d-block'} style={{maxHeight:'140px'}} src={!competition.image?"/images/interclubes.svg": competition.image} alt="Competicao" />
                            <div className="card-body">
                                <h4 className="card-title">{competition.title}</h4>
                                <p className="card-text">{competition.description}</p>

                                <div className="row">
                                    <div className="col-md-12 my-1">
                                        <Link to={`/tests/${competition.id}`} className="btn btn-block btn-primary">Acessar</Link>
                                    </div>

                                  {competition.status !== 'finished' && (
                                    <CompetitionCalendar competition_id={competition.id}/>
                                  )}

                                  {competition.show_result === 1 && (
                                    <div className="col-md-12 my-1">
                                        <Link to={`/competition/${competition.id}/results`} className="btn btn-block btn-info">Resultado</Link>
                                    </div>
                                  )}

                                </div>

                            </div>
                        </div>

                    </div>
                )}

            </div>
        </>
    );
}
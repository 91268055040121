import api from '../../services/api'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {Link, useParams} from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { SelectTheme } from '../../components/selectTheme'

export default function FormCalendar() {
    const navigate = useNavigate()

    const {id, competitionId} = useParams()
    const [stage, setStage] = useState<string>('')
    const [competition, setCompetitionId] = useState<number>()
    const [dates, setDates] = useState<string>('')
    const [team, setTeam] = useState<number>()
    const [club, setClub] = useState<string>('')
    const [arrayTeams, setArrayTeams] = useState([] as any)

    const dispatchTestsObject: any = {
        "stage": stage,
        "competition_id": competition,
        "dates": dates,
        "club": club,
        "team_id": team,
        "team": arrayTeams.find(option => option.value == team)?.label || '',
    }

    useEffect(() => {
        teams()

        if (id != undefined) {
            api.get('/api/calendars/' + id)
                .then(resposta => {
                    const test = resposta.data

                    setStage(test?.stage)
                    setCompetitionId(test?.competition_id)
                    setDates(test?.dates)
                    setClub(test?.club)
                    setTeam(test?.team_id)
                })
        }else{
            if (typeof competitionId === "string") {
                setCompetitionId(parseInt(competitionId))
            }
        }
        console.log(id, competitionId)
    }, [])

    const validadeRegister = () => {
        if (!stage) {
            throw "Etapa deve ser informada";
        }
    };

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            validadeRegister()

            const method:'post' | 'put' = id === undefined ? 'post' : 'put';
            const url:string = id === undefined ? '/api/calendars' : `/api/calendars/${id}`;

            api[method](url, dispatchTestsObject)
                .then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        timer: 3000
                    }).then(() => navigate(`/adm/competitions/calendar/${competition}`));
                })
                .catch(erro => {
                    Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.sqlMessage,
                        icon: 'error',
                    });
                });
        }catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }
    }

    async function teams(value = '') {
        const response = await api.get(`/api/teams`)
        const result = await response.data
        return setArrayTeams(
            await result.data.map((club) => ({
                label: club.fantasia,
                value: club.id,
            })),
        )
    }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className='text-left'>

                <div className='form-row'>

                    <div className='form-group col-md-4'>
                        <label htmlFor='stage'>Etapa <i className="text-danger"> *</i></label>
                        <input onChange={evento => setStage(evento.target.value)} type='text' className='form-control'
                               value={stage}
                               id='stage' placeholder='Etapa'/>
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='dates'>Datas </label>
                        <input onChange={evento => setDates(evento.target.value)} type='text'
                               className='form-control'
                               value={dates}
                               id='dates' placeholder='Datas da prova'/>
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='team'>Equipe </label>

                        <Select
                            options={arrayTeams}
                            name='team'
                            id='team'
                            onChange={(data: any) => {
                                setTeam(data.value)
                            }}
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            value={arrayTeams.find(option => option.value == team) || ''}
                            placeholder='Equipe'
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>
                </div>

                <div className='form-row'>


                    <div className='form-group col-md-4'>
                        <label htmlFor='club'>Clube </label>
                        <input onChange={evento => setClub(evento.target.value)} type='text'
                               className='form-control'
                               value={club}
                               id='club' placeholder='Clube da prova'/>
                    </div>


                </div>

                <div className='row py-4'>
                    <div className='col-md-12  text-right'>
                        <Link className='btn btn-danger mr-1' to={`/adm/competitions/calendar/${competition}`} title="Cancelar">Cancelar</Link>
                        <button type='submit' className='btn btn-success'> Salvar</button>
                    </div>
                </div>
            </form>
        </>

    )
}

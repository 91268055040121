import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { formatDa } from '../../assets/js/helper'
import IDate from '../../interfaces/IDate'
import api from '../../services/api'
import Swal from "sweetalert2";
import ProofRegistrationPrinting from "../test/ProofRegistrationPrinting";

export default function Dates() {
  const { id } = useParams()
  const [dates, setDates] = useState<IDate[]>([])
  const [registrations, setRegistrations] = useState<any>({ })
  const navigate = useNavigate()

  const tipoUser = JSON.parse(localStorage.getItem('token') || "").user.role

  useEffect(() => {
    api.get('/api/dates/test/' + id)
      .then(resposta => {
          setDates(resposta.data.data.dates)
          setRegistrations(resposta.data.data.registrations)
      })
  }, [])

    const reprocessResults = (test_id: number) => {
        api.post('/api/tests/reprocessResults/' + test_id)
            .then(resposta => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: "Resultados reprocessados com sucesso",
                    icon: 'success',
                    timer: 3000
                });
            }).catch(erro => {
                Swal.fire({
                    title: 'Erro!',
                    text: erro.response.data.sqlMessage,
                    icon: 'error',
                    timer: 5000
                });
            }
        );
    }

  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <h3 className='mb-2'>
            <b>Datas</b>
          </h3>
        </div>
        {tipoUser !== 'athlete' && (
          <div className='col-md-12' >
            <h5>
              <span className="badge badge-success"> <b>{registrations.registrations}</b> Inscrições </span> &nbsp;
              <span className="badge badge-success"> <b>{registrations.reRegistrations}</b> Reinscrições </span>
            </h5>
          </div>
        )}

          <div className='col-md-12'>
              {(dates[0]?.tests?.status === 'soon' && tipoUser === 'athlete') &&
                <ProofRegistrationPrinting test_id={id} />
              }

              {tipoUser !== 'athlete' &&
              <a className="btn btn-info ml-2" title="Quadro de atiradores" href="/frameShooters/8"><i className="fa fa-table" aria-hidden="true"></i> &nbsp;Quadro de atiradores</a>
              }

              {tipoUser === 'admin' &&
                <button type='button' className='btn btn-warning ml-2' onClick={() => reprocessResults(dates[0].test_id)}>Reprocessar resultados</button>
              }
          </div>

      </div>
      <div className='row my-4'>
        {dates.map(date =>
          <div key={date.id} className='col-md-4 mt-3'>
            <div className='card'>
              {/* <img className="card-img-top" src="https://static.pingendo.com/cover-moon.svg" alt="Card image cap" />*/}
              <div className='card-body'>
                <h2><b>{formatDa(date.date, 'data')}</b></h2>
                <Link to={'/classes/' + date.id} className='btn btn-block btn-primary'>Acessar</Link>
              </div>
            </div>
          </div>
        )}

      </div>
      <div className='row py-4'>
        <div className='col-md-12  text-right'>
          <button className='btn btn-danger btn-floating' onClick={() => navigate(`/tests/${dates[0]?.tests.competition_id}`)}>Voltar<br />
          </button>
        </div>
      </div>
    </>
  )
}

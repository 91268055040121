import {Link, useParams} from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import api from '../../services/api'
import { DataTableOptions } from '../../components/dataTableOptions'
import {useNavigate} from "react-router";
import ICalendar from "../../interfaces/ICalendar";
import Swal from "sweetalert2";

export default function ListingCalendars() {
  const navigate = useNavigate()
  const {id} = useParams()
  const [calendars, setCalendars] = useState<ICalendar[]>([])

  const user = JSON.parse(localStorage.getItem('token') || "").user

  const getTests = async () => {
    await api.post<ICalendar[]>(`/api/getCompetitionCalendar/${id}`).then((response) => {
      const data = response.data
      setCalendars(data)
    })
  }

  const deleteStage = (id) => {
    Swal.fire({
      icon: 'info',
      title: `Tem certeza que deseja excluir? `,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
          api.delete(`/api/calendars/${id}`).then(result => {
             getTests()
              Swal.fire({
                title: 'Sucesso!',
                text: result.data.message,
                icon: 'success',
              })
          }).catch(erro => {
              Swal.fire({
                title: 'Erro!',
                text: erro.response.data.sqlMessage,
                icon: 'error',
              });
          })
      } else  {
        return false
      }
    })

  }

  useEffect(() => {
    if(user?.role != "admin"){
      localStorage.setItem('hasError', JSON.stringify({title:"Atenção!" ,message:"Você não tem permissão. ",type: 'danger'}));
      return navigate("/home");
    }

    getTests()
  }, [])

  const columns: any = [
    {
      name: 'stage', label: 'Etapa', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'dates', label: 'Datas', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'team', label: 'Equipe', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: '', label: '', options: {
        filter: false,
        false: true,
        customBodyRenderLite: index => {return (
            <>
              <Link className='btn btn-sm btn-warning mr-2' to={`/adm/competitions/calendar/update/${calendars[index]?.id}`}
                    title="Editar"><i className="fa fa-pencil" aria-hidden="true"></i> </Link>
              <button className='btn btn-sm btn-danger' title="Excluir" onClick={() => deleteStage(calendars[index]?.id)}><i className="fa fa-trash-o" aria-hidden="true"></i></button>

            </>
        )
        },
      },
    },
  ]

  const options: any = DataTableOptions

  return (
      <>
        <h2>Calendário</h2>

        <div className='mb-4'>
          <Link className='btn btn-success mb-3' to={`/adm/competitions/calendar/create/${id}`}>Cadastrar Prova</Link>

          <MUIDataTable
              title={''}
              data={calendars}
              columns={columns}
              options={options}
          />
        </div>

        <div className='row'>
          <div className='col-md-12 text-right'>
            <Link className='btn btn-danger' to={`/adm/competitions`} title="Voltar">Voltar</Link>
          </div>
        </div>
      </>
  )
}

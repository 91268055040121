import {useContext} from 'react'
import api from '../../../services/api'
import Swal from 'sweetalert2'
import PasswordForm from "../../password/form";
import {PasswordContext} from "../../../context/PasswordContext";
import axios from "axios";
import {useNavigate} from "react-router";

export default function ChangePasswordForm() {
    const {password, currentPassword ,validatePasswordRegister, passwordIdent} = useContext(PasswordContext)
    const navigate = useNavigate()

    const forgot = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault()

            validatePasswordRegister(true)

            if (!passwordIdent()) {
                api.post('/api/change-password', {
                    currentPassword: currentPassword,
                    password: password
                }).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        timer: 5000,
                    }).then((result) => {
                        console.log('trocou')
                        axios.post(`${process.env.REACT_APP_API_HOST}/api/logout`)
                            .then((result) => {
                                localStorage.removeItem('token');
                                return navigate("/login");
                            })
                    })
                }).catch(erro => {
                        Swal.fire({
                            title: 'Erro!',
                            text: erro.response.data.message,
                            icon: 'error',
                        })
                    },
                )
            } else {
                Swal.fire({
                    title: 'Erro!',
                    text: 'Senhas devem ser iguais!',
                    icon: 'error',
                })
            }

        } catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }
    }

    return (<section className='text-center text-lg-start'>
                <div className='py-5 text-center'>
                    <div className='container'>
                        <div className='row'>
                            <div className='mx-auto col-md-10 col-10 bg-white p-5'>
                                <form onSubmit={forgot} autoComplete='off' className='text-left'>
                                    <h5>Preencha os campos abaixo para finalizar sua troca de senha.</h5>

                                    <PasswordForm changePassword={true} customLabel={{ password: 'Nova Senha', passwordConfirmation: "Confirme a nova senha" }}/>

                                    <button type='submit' className='btn btn-primary btn-block mb-4'>
                                        Alterar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>)
};

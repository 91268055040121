import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import ITest from "../../interfaces/ITest";
import api from "../../services/api";

export default function Test() {

    const [tests, setTests] = useState<ITest[]>([])
    const navigate = useNavigate();
    const {id} = useParams();
    const tipoUser = JSON.parse(localStorage.getItem('token') || "").user.role

  useEffect(() => {
        // axios.get<ITest[]>('http:///api/tests')
        api.get('/api/tests/competition/' + id)
            .then(resposta => setTests(resposta.data.data))
    }, [])


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h3 className="mb-2">
                        <b>Provas</b>
                    </h3>
                </div>
            </div>
            <div className="row my-4">

                {tests.map(test =>
                    <div key={test.id} className="col-md-4 mt-3">
                        <div className="card"> <img className={test.status === 'finished' ? 'card-img-top img-blur' : 'card-img-top'} src={!test.image ? (!test.team.image ? (!test.team.club.image ? "/images/interclubes.svg" : test.team.club.image) : test.team.image) : test.image } style={{maxHeight:'240px'}} alt="Logo prova" />
                            <div className="card-body">
                                <h4 className="card-title">{test.title}</h4>
                                <p className="card-text">{test.description}</p>

                                <div className="row">
                                  {(test.status != 'finished' || tipoUser != 'athlete') &&
                                    <>
                                      <div className="col-sm-12 my-1">
                                            <Link to={"/dates/" + test.id} className="btn btn-block btn-primary">Acessar</Link>
                                        </div>

                                      {!(!test.team?.club.maps_location) && test.status != 'finished' &&
                                        <div className={(test.show_result === 1)? 'col-sm-6 my-1' : 'col-sm-12 my-1'}>
                                            <button type="button" className="btn btn-block btn-warning" data-toggle="modal" data-target={`#modalLocation-${test.id}`} >
                                                &nbsp;<i className="fa fa-map-marker" aria-hidden="true"></i>
                                                &nbsp;Localização
                                            </button>
                                        </div>
                                      }
                                    </>
                                  }

                                  {(test.show_result === 1) &&
                                      <div className={test.status != 'finished' && !(!test.team?.club.maps_location) ? "col-md-6 my-1" : "col-md-12 my-1"}>
                                          <Link to={`/test/${test.id}/results`} className="btn btn-block btn-info">Resultado</Link>
                                      </div>
                                  }

                                </div>

                            </div>
                        </div>


                      {!(!test.team?.club.maps_location) &&
                        <div className="modal fade" id={`modalLocation-${test.id}`} role="dialog" aria-labelledby="modalLocationLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Localização</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <iframe
                                            src={test.team?.club.maps_location}
                                            width="100%" height="100%" loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Sair</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                      }
                    </div>
                )}

            </div>

            <div className="row py-4">
                 <div className="col-md-12  text-right" >
                   <button className="btn btn-danger btn-floating" onClick={() => navigate(`/competitions`)} >Voltar<br /></button> </div>
            </div>
        </>
    );
}

import api from '../../services/api'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {Link, useParams} from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { SelectTheme } from '../../components/selectTheme'

export default function FormTests() {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('token') || "").user

    let {id} = useParams()
    const [active, setActive] = useState('1')
    const [showResult, setShowResult] = useState(1)
    const [status, setStatus] = useState('soon')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [series, setSeries] = useState('1')
    const [maximumInscriptions, setMaximumInscriptions] = useState('1' as any)
    const [timeInterval, setTimeInterval] = useState('30' as any)
    const [stage, setStage] = useState('' as any)
    const [final, setFinal] = useState('0' as any)
    const [competitionId, setCompetitionId] = useState('' as any)
    const [team, setTeam] = useState('' as any)
    const [arrayTeams, setArrayTeams] = useState([] as any)

    const dispatchTestsObject: any = {
        "id": id,
        "active": active,
        "status": status,
        "show_result": showResult,
        "title": title,
        "description": description,
        "series": series,
        "maximum_inscriptions": maximumInscriptions,
        "time_interval": timeInterval,
        "stage": stage,
        "final": final,
        "image": null,
        "team_id": team,
        "competition_id": competitionId,
    }

    useEffect(() => {
        teams()

        if (id != undefined) {
            api.get('/api/tests/' + id)
                .then(resposta => {
                    const test = resposta.data.data

                    setActive(test?.active)
                    setStatus(test?.status)
                    setShowResult(test?.show_result)
                    setTitle(test?.title)
                    setDescription(test?.description)
                    setSeries(test?.series)
                    setMaximumInscriptions(test?.maximum_inscriptions)
                    setTimeInterval(test?.time_interval)
                    setStage(test?.stage)
                    setFinal(test?.final)
                    setTeam(test?.team_id)
                    setCompetitionId(test?.competition_id)
                })
        }
    }, [])

    const validadeRegister = () => {

        if (!title) {
            throw "Titulo deve ser informado";
        }

        if (!maximumInscriptions) {
            throw "Maximo de ReInscrições deve ser informado";
        }

        if (!stage) {
            throw "Etapa deve ser informada";
        }

        if (!team) {
            throw "Equipe deve ser informada";
        }

    };

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();

            validadeRegister()

            const method:'post' | 'put' = id === undefined ? 'post' : 'put';
            const url:string = id === undefined ? '/api/tests' : `/api/tests/${id}`;

            api[method](url, dispatchTestsObject)
                .then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        timer: 3000
                    }).then(() => navigate("/adm/tests"));
                })
                .catch(erro => {
                    Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.sqlMessage,
                        icon: 'error',
                    });
                });
        }catch (err: any) {
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }
    }

    async function teams(value = '') {
        const response = await api.get(`/api/teams`)
        const result = await response.data
        return setArrayTeams(
            await result.data.map((club) => ({
                label: club.fantasia,
                value: club.id,
            })),
        )
    }

    return (
        <>
            <form onSubmit={resgister} autoComplete='off' className='text-left'>

                <div className='form-row'>

                    <div className='form-group col-md-4'>
                        <label htmlFor='active'>Ativo <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Ativo', value: '1'}}
                            options={[{label: 'Ativo', value: '1'}, {label: 'Inativo', value: '0'}]}
                            value={[{label: 'Ativo', value: '1'}, {
                                label: 'Inativo',
                                value: '0',
                            }].find(option => option.value == active)}
                            name='active'
                            id='active'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setActive(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='status'>Status <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Em breve', value: 'soon'}}
                            options={[{label: 'Em breve', value: 'soon'}, {
                                label: 'Em andamento',
                                value: 'in_progress'
                            }, {label: 'Finalizada', value: 'finished'}]}
                            value={[{label: 'Em breve', value: 'soon'}, {
                                label: 'Em andamento',
                                value: 'in_progress'
                            }, {label: 'Finalizada', value: 'finished'}
                            ].find(option => option.value == status)}
                            name='status'
                            id='status'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setStatus(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='show_result'>Mostrar resultados <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Sim', value: 1}}
                            options={[{label: 'Sim', value: 1}, {label: 'Não', value: 0}]}
                            value={[{label: 'Sim', value: 1}, {label: 'Não', value: 0}
                            ].find(option => option.value == showResult)}
                            name='show_result'
                            id='show_result'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setShowResult(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />
                    </div>

                    <div className='form-group col-md-12'>
                        <label htmlFor='title'>Titulo <i className="text-danger"> *</i></label>
                        <input onChange={evento => setTitle(evento.target.value)} type='text' className='form-control'
                               value={title}
                               id='title' placeholder='Titulo'/>
                    </div>

                    <div className='form-group col-md-12'>
                        <label htmlFor='description'>Descrição </label>
                        <input onChange={evento => setDescription(evento.target.value)} type='text'
                               className='form-control'
                               value={description}
                               id='description' placeholder='Seu description'/>
                    </div>
                </div>

                <div className='form-row'>

                    <div className='form-group col-md-4'>
                        <label htmlFor='series'>Qtd Tiros <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: '25', value: '1'}}
                            options={[{label: '25', value: '1'}, {label: '50', value: '2'}, {
                                label: '75',
                                value: '3'
                            }, {label: '100', value: '4'}]}
                            value={[{label: '25', value: '1'}, {label: '50', value: '2'}, {
                                label: '75',
                                value: '3'
                            }, {label: '100', value: '4'}
                            ].find(option => option.value == series)}
                            name='setrie'
                            id='setrie'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setSeries(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='maximum_inscriptions'>Maximo de ReInscrições <i
                            className="text-danger"> *</i></label>
                        <input onChange={evento => setMaximumInscriptions(evento.target.value)} type='number'
                               className='form-control'
                               value={maximumInscriptions}
                               id='maximum_inscriptions' placeholder='Quantidade'/>
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='time_interval'>Intervalo entre cada inscrição <i
                            className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: '30 mim', value: '30'}}
                            options={[{label: '15 mim', value: '15'}, {label: '30 mim', value: '30'}, {
                                label: '45 mim',
                                value: '45'
                            }, {label: '1h', value: '60'}]}
                            value={[{label: '15 mim', value: '15'}, {label: '30 mim', value: '30'}, {
                                label: '45 mim',
                                value: '45'
                            }, {label: '1h', value: '60'}
                            ].find(option => option.value == timeInterval)}
                            name='time_interval'
                            id='time_interval'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setTimeInterval(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='stage'>Etapa <i className="text-danger"> *</i></label>
                        <input onChange={evento => setStage(evento.target.value)} type='text' className='form-control'
                               value={stage}
                               id='stage' placeholder='etapa'/>
                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='final'>Final <i className="text-danger"> *</i></label>
                        <Select
                            defaultValue={{label: 'Sim', value: '1'}}
                            options={[{label: 'Sim', value: '1'}, {label: 'Não', value: '0'}]}
                            value={[{label: 'Sim', value: '1'}, {
                                label: 'Não',
                                value: '0',
                            }].find(option => option.value == final)}
                            name='final'
                            id='final'
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            onChange={(data: any) => {
                                setFinal(data.value)
                            }}
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                    <div className='form-group col-md-4'>
                        <label htmlFor='team'>Equipe <i className="text-danger"> *</i></label>

                        <Select
                            options={arrayTeams}
                            name='team'
                            id='team'
                            isDisabled={user?.role != "admin"}
                            onChange={(data: any) => {
                                setTeam(data.value)
                            }}
                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                            value={arrayTeams.find(option => option.value == team) || ''}
                            placeholder='Equipe'
                            theme={(theme) => (SelectTheme(theme))}
                        />

                    </div>

                </div>

                <div className='row py-4'>
                    <div className='col-md-12  text-right'>
                        <Link className='btn btn-danger mr-1' to={`/adm/tests`} title="Cancelar">Cancelar</Link>
                        <button type='submit' className='btn btn-success'> Salvar</button>
                    </div>
                </div>
            </form>
        </>

    )
}
